export default {
  ROLE: {
    superuser: "Администратор системы",
  },
  MESSAGE: {
    SUCCESS: "Успешно.",
    ERROR: {
      NOT_FOUND:
        "Элемент не найден.<br />Обратитесь к администратору за дополнительными сведениями.",
      SERVER:
        "Произошла ошибка.<br />Обратитесь к администратору за дополнительными сведениями.",
      FORM: "Заполните все необходимые поля.",
      LOGIN: "Неправильный логин или пароль.",
      FORBIDDEN:
        "Доступ запрещен. В данный момент у вас нет доступа к этой странице.",
    },
  },
  GEOTEK: {
    SETTINGS: {
      subUserId: 0,
      userName: "NSS",
      password: "26092020",
    },
  },
  DEFECT: {
    statuses: {
      NEW: {
        text: "Неисправен",
        value: "NEW",
        color: "#626262",
        bgColor: "#FFDADA",
        textColor: "#8B150C",
      },
      ON_REPAIR: {
        text: "Акт сформирован",
        value: "ON_REPAIR",
        color: "#FF9500",
        bgColor: "#FCF0DB",
        textColor: "#7F3004",
      },
      APPROVED: {
        text: "Исправлен",
        value: "APPROVED",
        color: "#34C759",
        bgColor: "#D6F3E9",
        textColor: "#045130",
      },
    },
  },
  STOREHOUSE_ORDERS: {
    statuses: {
      NEW: {
        text: "Сформировано",
        value: "NEW",
        color: "#CED4DA",
        bgColor: "#EBF1FF",
        textColor: "#3466E7",
      },
      CHECKED: {
        text: "В обработке зав.склада",
        value: "CHECKED",
        color: "#F8C700",
        bgColor: "#F2F4F7",
        textColor: "#667085",
      },
      WAITING_FOR_PURCHASE: {
        text: "На закуп",
        value: "WAITING_FOR_PURCHASE",
        color: "#F8C700",
        bgColor: "#EBDBFC",
        textColor: "#66047F",
      },
      IN_PROGRESS: {
        text: "В работе",
        value: "IN_PROGRESS",
        color: "#F8C700",
        bgColor: "#FCF0DB",
        textColor: "#7F3004",
      },
      PURCHASED: {
        text: "Закуплено",
        value: "PURCHASED",
        color: "#F76707",
        bgColor: "#FFE5F6",
        textColor: "#76015C",
      },
      READY_FOR_ISSUE: {
        text: "Готово к выдаче",
        value: "READY_FOR_ISSUE",
        color: "#3466E7",
        bgColor: "#FCF0DB",
        textColor: "#7F3004",
      },
      ISSUED: {
        text: "Готов к ремонту",
        value: "ISSUED",
        color: "#0EC25C",
        bgColor: "#D6F3E9",
        textColor: "#045130",
      },
    },
  },
  TICKET: {
    statuses: {
      NEW: { text: "Новый", bgColor: "#EBF1FF", textColor: "#3466E7" },
      IN_PROGRESS: {
        text: "В работе",
        bgColor: "#FCF0DB",
        textColor: "#7F3004",
      },
      PAUSED: {
        text: "Работа приостановлена",
        bgColor: "#F2F4F7",
        textColor: "#667085",
      },
      ON_ACCEPTOR_REVIEW: {
        text: "Нa приемке",
        bgColor: "#FFE5F6",
        textColor: "#76015C",
      },
      ACCEPTOR_REJECTED: {
        text: "Отклонена приемщиком",
        bgColor: "#FFDADA",
        textColor: "#8B150C",
      },
      FINALIZED: {
        text: "Завершена",
        bgColor: "#D6F3E9",
        textColor: "#045130",
      },
    },
  },
  EQUIPMENT: {
    statuses: {
      ACTIVE: {
        text: "На линии",
        value: "ACTIVE",
        color: "#34C759",
      },
      IDLE: {
        text: "На простое",
        value: "IDLE",
        color: "#FF9500",
      },
      ARCHIVED: {
        text: "Архивирован",
        value: "ARCHIVED",
        color: "#FF3B30",
      },
      REPAIR: {
        text: "На ремонте",
        value: "REPAIR",
        color: "#FF3B30",
      },
    },
  },
  UNITS: ["шт", "кг", "л", "компл.", "метр", "см", "мм"],
};
