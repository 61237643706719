export default {
  namespaced: true,
  state: {
    pagination: {
      itemsPerPage: 10,
      page: 1,
      pageCount: 1,
      footerProps: {
        itemsPerPageOptions: [5, 10, 50, 100, -1],
      },
    },
    filter: {
      search: null,
      type: "license_plate_number",
      classification_id: null,
      brand_id: null,
      model_id: null,
      column_id: null,
    },
  },
  mutations: {
    setFilter(state, payload) {
      state.filter = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
  },
  getters: {
    pagination(state) {
      return state["pagination"];
    },
    filter(state) {
      return state["filter"];
    },
  },
  actions: {
    setPagination({ commit }, payload) {
      commit("setPagination", payload);
    },
    setFilter({ commit }, payload) {
      commit("setFilter", payload);
    },
  },
};
