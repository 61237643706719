import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "@/store/modules/user";
import menu from "@/store/modules/menu";
import loading from "@/store/modules/loading";
import notification from "@/store/modules/notification";
import handbook from "@/store/modules/handbook";
import workspace from "@/store/modules/workspace";
import equipment from "@/store/modules/equipment";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user: user,
    menu: menu,
    loading: loading,
    notification: notification,
    handbook: handbook,
    workspace: workspace,
    equipment,
  },
});
